.Full-Width-Flex {
    width: 100%;
    display: flex;
    min-height: 10px;
    margin: auto 0;
    background-color: #ffffff;
    row-gap: 10px !important;
    column-gap: 20px !important;
}


.Half-Width-Section-2COl-Container {
    display: flex;
    width: 100%;
    margin: auto 0;
    align-items: stretch;
    align-content: stretch;
    background-color: #ffffff;
}

.Half-Width-Section-Image {
    width: 50%;
    margin: 0 auto;
    float: left !important;
    background-color: #ffffff !important;
    color: #ffffff !important;
}

.Half-Width-Section-WhiteBG {
    width: 50%;
    margin: auto 0;
    float: left !important;
    background-color: #ffffff !important;
    color: #ffffff !important;
}



/***** DESKTOP CSS ****/
.Full-Page {
    width: 100% !important;
    max-width: 1600px !important;
    margin: 0 auto;
    padding: 5px;
    background-color: #ffffff;
}



.BTN-BACK-TOP {
    display: none !important;
}


.Table-White-Cell-25 {
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    border-right: 1px solid #ffffff;
    width: 25%;
}

.Table-Grey-Cell-25 {
    background-color: #eaeaea;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    border-right: 1px solid #ffffff;
    width: 25%;
}

@font-face {
    font-family: 'CustomFont-Light';
    /*src: url(https://cdn1-originals.webdamdb.com/14049_124417589?cache=1627484657&response-content-disposition=inline;filename=CustomFont-Light.otf&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cCo6Ly9jZG4xLW9yaWdpbmFscy53ZWJkYW1kYi5jb20vMTQwNDlfMTI0NDE3NTg5P2NhY2hlPTE2Mjc0ODQ2NTcmcmVzcG9uc2UtY29udGVudC1kaXNwb3NpdGlvbj1pbmxpbmU7ZmlsZW5hbWU9VG95b3RhRm9udC1MaWdodC5vdGYiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjIxNDc0MTQ0MDB9fX1dfQ__&Signature=Shs10i1Tn8PV2AyCAb78HB~IGZQqJS9kWLywvQsVq4P4FVMkLYnttDv31A-pkWqhvYbe55TQvBnLIYWO-F4GS6qQsuQs1pucStB6Mp44ObeNKnlrY9iWaG4bbnAzAMYXBlOLNKJ2aT6Zg5h6xIlXi-xawR3WKX3tnoWBVcAo1XqUDo4FpsyYc9ZQn36KIkQjiPOAWXyNgrmoE3qDmUNhQYMoqwhDM6DeRV3ZZiOjQ6dLMpttx7gTBvNZvs662rgMNlxw2cDL221wRshxmISXXNZOfnMSb06Vh1G63ZOzKkLujpIc-aPJya44NSkqzYkVj3LhSe9qC6Berti4gEimWQ__&Key-Pair-Id=APKAI2ASI2IOLRFF2RHA);*/
}


@font-face {
    font-family: 'CustomFont-Regular';
    /*src: url(https://cdn1-originals.webdamdb.com/14049_124417591?cache=1627484657&response-content-disposition=inline;filename=CustomFont-Regular.otf&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cCo6Ly9jZG4xLW9yaWdpbmFscy53ZWJkYW1kYi5jb20vMTQwNDlfMTI0NDE3NTkxP2NhY2hlPTE2Mjc0ODQ2NTcmcmVzcG9uc2UtY29udGVudC1kaXNwb3NpdGlvbj1pbmxpbmU7ZmlsZW5hbWU9VG95b3RhRm9udC1SZWd1bGFyLm90ZiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MjE0NzQxNDQwMH19fV19&Signature=Pgsqb1rgcrIDsGC7YZMElApNy--mvQ-F2FPCntTxQnQFXrV6GjR9K784C-xeUW~Oom2MGot-CXKKO3wLvcqKDzxKjmZpClNDdWj~QSqC8Mn7jyuxq-YK9kscw5uWRb5Odi~E3sUkbMVIxmHwG38EYl75h-wT4tB5WDKL~SQl1H54fR~YtOoPkDAPx0oPPsIS5sGb-ZzDylm6zuIKKKNsh2RWz3sUmEGMidol0K1It6kkCXZSSba-r~-H7G4pXTj3x5AbhPvc~lgfZs3bn~G~WHJymlmPJ-W9RL4x4G0L4DV3n-boJXds4OneMjoTruS7prb5ao5ulHTmTBhQTXpIOQ__&Key-Pair-Id=APKAI2ASI2IOLRFF2RHA);*/
}


@font-face {
    font-family: 'CustomFont-Bold';
    /*src: url(https://cdn1-originals.webdamdb.com/14049_124417592?cache=1627484657&response-content-disposition=inline;filename=CustomFont-Bold.otf&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cCo6Ly9jZG4xLW9yaWdpbmFscy53ZWJkYW1kYi5jb20vMTQwNDlfMTI0NDE3NTkyP2NhY2hlPTE2Mjc0ODQ2NTcmcmVzcG9uc2UtY29udGVudC1kaXNwb3NpdGlvbj1pbmxpbmU7ZmlsZW5hbWU9VG95b3RhRm9udC1Cb2xkLm90ZiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MjE0NzQxNDQwMH19fV19&Signature=b450geT1wHNmnj4ZlVDScQlPQhUIkoNB4BGIoB1YgBEqTVqYI~LSRsBF0t04fFkDFTB8b-rgCQZ6TJjL77f0GVZfmK8u0zHicRvDdLjdqxV3Phztr9tnlRIYuNZtcjRuAGcu-AfHXYhtuYfUkmH8MFuPnf3k7CJa3srD2q~BHc3Oc15Mq9Fjr0fqgVtSEH3O8N6ma6wtESQERps2beuF~iDD~Jt15szDFNEliWSGNWn7ZmDVrjMwaRdBRhqRF6SYdXwqGoD-sppigEM-eH2ZJ86lA2KQtkxWQMVPm5~jLpES851TY-BvX0X9YC9v~21QMUj-g~971LuEy4usMxN7Jw__&Key-Pair-Id=APKAI2ASI2IOLRFF2RHA);*/
}

.p-text {
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 16px !important;
    line-height: 22.5px !important;
    text-align: left;
    color: inherit !important;
    margin-bottom: 10px;
}


.p-text-bold {
    font-family: 'CustomFont-Bold', sans-serif;
    color: inherit !important;
}


.p-text-light {
    font-family: 'CustomFont-Light', sans-serif;
    color: inherit !important;
}


.Tab-SubImage {
    width: 100%;
    height: auto;
    min-height: 275px;
    overflow: hidden;
}


.Basic-Comparison-Image {
    width: 100%;
    max-width: 800px !important;
    float: left !important;
}


.Quiz-iFrame {
    border: 1px #ec37a0 solid !important;
    padding: 5px;
    width: 85% !important;
    height: 550px !important;
    overflow: scroll !important;
}



#animated_div-review {
    width: 100%;
    max-width: 700px !important;
    margin-top: -10px !important;
    padding: 5px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50% !important;
}




#animated_div-left {
    width: 100%;
    max-width: 600px !important;
    padding: 10px;
    margin-bottom: 5px !important;
    float: left !important;
    z-index: 100;
}




#animated_div-right {
    width: 100%;
    max-width: 600px !important;
    padding: 10px;
    margin-bottom: 5px !important;
    float: right !important;
    z-index: 1;
}



.Intro-Full-Width-Container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.Intro-Grey-Block {
    background-color: #f5f5f5;
    width: 100%;
    margin: 0 auto !important;
    padding: 40px;
    min-height: 200px;
    display: block;
    flex-basis: 100%;
}


.AtGlance-Container-Premium {
    background-color: #f5f5f5;
    color: #111111;
    margin: 0 auto !important;
    padding: 25px;
    flex-basis: 50%;
    width: 50%;
    box-sizing: border-box;
    margin-top: -20px !important;
}



.QuickStats-Container-Half {
    background-color: #111111;
    padding: 0px 0px 20px 0px;
    flex-basis: 50%;
    width: 50%;
    box-sizing: border-box;
    margin-top: -20px !important;
}


.AtGlance-Container-Basic {
    background-color: #4c4c4c;
    color: #ffffff;
    margin: 0 auto !important;
    padding: 25px;
    margin-bottom: -10px !important;
}


.QuickStats-Container-Full {
    width: 100%;
    background-color: #111111 !important;
    margin: 0 auto !important;
    padding: 0px !important;
    flex-basis: 100%;
    margin-bottom: 20px !important;
}



.QuickStats-Container-Basic {
    border: 1px solid #4c4c4c;
    background-color: #ffffff;
    margin: 0 auto !important;
    padding: 10px 0px;
}


.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}


.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 15px !important;
}


.custom-h1 {
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #111111;
    text-align: center;
}

.custom-h2 {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 34px;
    line-height: 36.5px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    color: #111111;
    text-align: left;
}

.custom-h2-white {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 34px;
    line-height: 36.5px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    color: #ffffff;
    text-align: left;
}

.custom-h3 {
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 900;
    color: #111111;
    text-align: left;
}

.custom-h3-white {
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 900;
    color: #FFFFFF;
    text-align: left;
}

.custom-h4 {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #111111;
    text-align: left;
}

.custom-h4-white {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #ffffff;
    text-align: left;
}

.custom-h5 {
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #111111;
    text-align: left;
}

.custom-h5-white {
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #ffffff;
    text-align: left;
}


.Dare-to-Compare {
    font-family: 'CustomFont-Light', sans-serif;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 15px;
    letter-spacing: 1px;
    color: #ec37a0;
}


.Accent-Underline {
    border-bottom: solid 2px #ec37a0;
    width: 70%;
    margin-bottom: 20px;
}

.FullImage-BG {
    width: 100%;
    height: auto;
    min-height: 300px;
    position: static;
    overflow: hidden;
    padding: 60px 40px 60px 40px;
}


.Image-Text {
    width: 60%;
    float: left !important;
    padding: 0px 15px;
    height: auto;
    color: #ffffff;
    text-align: left;
    margin: 0 auto !important;
}


.Image-Link-Section {
    width: 40%;
    float: left !important;
    padding: 0px 15px;
    height: auto;
    color: #ffffff !important;
    text-align: left;
}


.CTA-BTN-Large-Full {
    background-color: #ec37a0;
    border: none;
    color: #ffffff !important;
    clear: both !important;
    padding: 16px 32px;
    text-align: center;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 16px;
    display: block;
    text-decoration: none !important;
    opacity: 100%;
    width: 100%;
    margin-bottom: 15px !important;
    margin-top: 10px !important;
    border-radius: 8px;
}


.CTA-BTN-Large-Full:hover {
    opacity: 0.85;
    color: #ffffff !important;
    text-decoration: none !important;
    width: 100%;
}



.CTA-BTN-Large {
    background-color: #ec37a0;
    border: none;
    color: #ffffff !important;
    padding: 16px 32px;
    text-align: center;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 16px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
    margin-top: 25px;
    border-radius: 8px;
}


.CTA-BTN-Large:hover {
    opacity: 0.85;
    color: #ffffff !important;
    text-decoration: none;
}


.CTA-BTN-Small {
    background-color: #ec37a0;
    border: none;
    color: #ffffff !important;
    padding: 8px 15px;
    max-width: 120px;
    text-align: center;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 12px;
    margin-top: 15px !important;
    margin: 0 auto !important;
    opacity: 1;
    transition: 0.3s;
    display: block;
    text-decoration: none;
    cursor: pointer;
    border-radius: 6px;
}


.CTA-BTN-Small:hover {
    opacity: 0.75;
    color: #ffffff !important;
    text-decoration: none;
}



.Full-Width-Section-Premium {
    width: 100%;
    margin-bottom: 30px !important;
    min-height: 100px;
    background-color: #ffffff;
}

.Full-Width-Section-Basic {
    width: 100%;
    padding: 20px 10%;
    min-height: 200px;
}


.Full-Width-Tab {
    width: 100%;
    padding: 25px;
    min-height: 300px;
}


.Half-Width-Section {
    width: 50%;
    padding: 8px;
    margin: 0 auto !important;
    float: left !important;
}


.Half-Width-Comparison-Images {
    width: 50%;
    padding: 8px;
    margin: 0 auto !important;
    float: left !important;
}


.Fourth-Width-Section {
    width: 24%;
    padding: 12px;
    margin: 0 auto !important;
    float: left !important;
    background-color: #ffffff;
}

.ThreeFourth-Width-Section {
    width: 74%;
    padding: 12px;
    margin: 0 auto !important;
    float: left !important;
    background-color: #ffffff;
}


.Third-Width-Section {
    width: 33%;
    padding: 10px 0px 0px 0px;
    margin: 0 auto !important;
    float: left !important;
    background-color: inherit !important;
}


.TwoThirds-Width-Section {
    width: 66%;
    padding: 10px;
    margin: 0 auto !important;
    float: left !important;
    background-color: inherit !important;
}


.Grey-BG {
    background-color: #eaeaea;
    margin: 0 auto !important;
    min-height: 100px;
    height: auto;
    padding: 40px;
    margin-bottom: 30px !important;
}


.White-BG {
    background-color: #ffffff;
    margin: 0 auto !important;
    min-height: 100px;
    height: auto;
    padding: 40px;
    margin-bottom: 30px !important;
}


.Main-PNG {
    width: 100%;
    max-width: 750px !important;
    margin-top: 25px;
}



.Comparison-PNG {
    width: 100%;
    max-width: 400px !important;
}


.Outro-Jellybean {
    width: 100%;
    max-width: 300px !important;
    padding: 0px 10px 0px 0px !important;
    float: left !important;
}



.Quick-Stat-Block-Quarter {
    width: 25%;
    min-height: 100px;
    margin: 0 auto !important;
    float: left !important;
    padding: 0px 0px 20px 0px;
}


.Quick-Stat-Block-Third {
    width: 33.3%;
    min-height: 100px;
    float: left !important;
    padding: 0px 0px 25px 0px;
}



.Quick-Stat-Block-Half {
    width: 50%;
    min-height: 100px;
    float: left !important;
}


.Quick-Stat-Title-Light {
    color: #ffffff;
    text-align: center;
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 22px;
    line-height: 23px;
    vertical-align: bottom;
}


.Quick-Stat-Title-Dark {
    color: #111111;
    text-align: center;
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 16px;
    line-height: 19px;
    vertical-align: bottom;
}


.Quick-Stat-Icon {
    text-align: center;
    width: 100%;
    max-width: 60px !important;
    vertical-align: middle;
    display: block !important;
    height: 50% !important;
    margin-bottom: 10px;
}


.Quick-Stat-Icon-Container {
    background-color: #ec37a0;
    margin-top: -15px !important;
    margin-bottom: 15px !important;
    padding: 20px 0px !important;
    min-height: 145px;
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}




.Stats-Color {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 52px;
    line-height: 54px;
    margin: 10px 0px 5px 0px;
    color: #ec37a0;
    vertical-align: bottom;
}


.Stats-Dark {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 52px;
    line-height: 54px;
    margin: 10px 0px 5px 0px;
    color: #111111;
    vertical-align: bottom;
}


.Divider {
    font-family: 'CustomFont-Light', sans-serif;
    font-size: 52px;
    line-height: 54px;
    margin-bottom: 10px;
    color: #eaeaea;
    vertical-align: center;
}

.Stats-Small-Color {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 30px;
    line-height: 40px;
    margin: 10px 0px 5px 0px;
    color: #ec37a0;
    vertical-align: bottom;
}


.Stats-Small-Dark {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 30px;
    line-height: 40px;
    margin: 10px 0px 5px 0px;
    color: #111111;
    vertical-align: bottom;
}

.Stats-Small-HP {
    font-size: 20px !important;
}

.Stats-Models-Color {
    color: #ec37a0;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
}


.Stats-Models-Dark {
    color: #111111;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
}


.Disclaimer-Text {
    color: #111111;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 10px;
    line-height: 12px;
}


/* INFO TABS */

.tabimage {
    width: 100%;
    max-width: 800px !important;
    margin-top: 10px;
    margin-bottom: 20px;
}


/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    width: 99.9%;
    min-height: 130px;
    margin: 0 auto !important;
}


/* Style the buttons inside the tab */
.tab button {
    background-color: #ffffff;
    width: 33.3% !important;
    max-width: 800px !important;
    float: left !important;
    border: 1px solid #eaeaea;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 26px;
    font-weight: 400;
    min-height: 130px;
    margin: 0 auto !important;
}


/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #111111;
    color: #ffffff;
    float: left;
    font-family: 'CustomFont-Bold', sans-serif;
    margin: 0 auto !important;
}


/* Create an active/current tablink class */
.tab button.active {
    background-color: #111111;
    color: #ffffff;
    float: left;
    font-family: 'CustomFont-Bold', sans-serif;
    margin: 0 auto !important;
}


/* Style the tab content */
.tabcontent {
    display: none;
    align-content: center;
    margin: 0 auto !important;
}



.Chart-Settings {
    background: #ffffff;
    margin: auto;
    padding: 5px;
    width: 99%;
    border: 1px solid #ffffff;
}


.Table-Vehicle-Row {
    border-bottom: 1px solid #ffffff;
}


.Table-Model-Title-Dark {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 10px;
    color: #111111;
}


.Table-Model-Title-Light {
    font-family: 'CustomFont-Bold', sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
}


.Table-BlankBlock-BG {
    background-color: #ec37a0;
    width: 33.3%;
}


.Table-ModelImage-BG {
    background-color: #ec37a0;
    color: #111111;
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    width: 33.3%;
}


.Table-ComparisonImage-BG {
    background-color: #ffffff;
    color: #111111;
    border-right: #eaeaea 1px solid;
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    width: 33.3%;
}


.Table-Category-Block {
    background: #111111;
    color: #ffffff;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    width: 33.3%;
}


.Table-Row {
    border-top: 1px solid #ffffff;
    /* border-collapse: collapse; */
    border-bottom: 1px solid #ffffff;
}


.Table-White-Cell {
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    border-right: 1px solid #ffffff;
    width: 33.3%;
}


.Table-Grey-Cell {
    background-color: #eaeaea;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    font-family: 'CustomFont-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    border-right: 1px solid #ffffff;
    width: 33.3%;
}



/***** TABLET CSS *****/
@media screen and (max-width: 1025px) and (min-width: 620px) {

    .Full-Width-Flex {
        width: 100%;
        display: block !important;
        min-height: 10px;
        background-color: #ffffff;
    }

    .Half-Width-Section-2COl-Container {
        display: flex;
        width: 100%;
        margin: auto 0;
        align-items: stretch;
        align-content: stretch;
        margin-bottom: 20px !important;
    }


    .Half-Width-Section-Image {
        width: 50%;
        padding: 0px;
        margin: 0 auto;
        float: left;
        display: block;
        background-color: #000000 !important;
        color: #ffffff !important;
    }


    .Half-Width-Section-WhiteBG {
        width: 50%;
        padding: 0px;
        margin: auto 0;
        float: left;
        display: block;
        background-color: #ffffff !important;
        color: #ffffff !important;
    }



    .BTN-BACK-TOP {
        display: block !important;
    }


    #animated_div-review {
        width: 100%;
        max-width: 850px !important;
    }


    .Table-White-Cell-25 {
        padding: 10px 5px;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 10px;
        line-height: 12px;
    }


    .Table-Grey-Cell-25 {
        padding: 10px 5px;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 10px;
        line-height: 12px;
    }

    .Quiz-iFrame {
        width: 100% !important;
        margin-top: 15px !important;
    }

    .Intro-Full-Width-Container {
        width: 100%;
        min-height: 100px;
        display: block;
    }


    .p-text {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 15px !important;
        line-height: 21px !important;
    }


    .p-text-bold {
        font-family: 'CustomFont-Bold', sans-serif;
    }




    .Tab-SubImage {
        width: 100%;
        height: auto;
        min-height: 350px;
        overflow: hidden;
    }

    .AtGlance-Container-Premium {
        float: none !important;
        flex-basis: 100% !important;
        display: block;
        padding: 20px;
        width: 100%;
        margin-top: -30px !important;
    }



    .QuickStats-Container-Half {
        float: none !important;
        flex-basis: 100% !important;
        width: 100%;
        display: block;
        margin-bottom: -20px !important;
        margin-top: -25px !important;
    }

    .QuickStats-Container-Full {
        padding: 20px;
    }



    .AtGlance-Container-Basic {
        margin-top: -20px !important;
    }


    .custom-h1 {
        font-family: 'CustomFont-Light', sans-serif;
        font-size: 36px;
        line-height: 39px;
        letter-spacing: 1px;
        color: #111111;
    }

    .custom-h2 {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #111111;
    }

    .custom-h2-white {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #FFFFFF;
    }

    .custom-h3 {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #111111;
    }

    .custom-h3-white {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #FFFFFF;
    }


    .custom-h4 {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #111111;
    }

    .custom-h4-white {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff;
    }

    .custom-h5 {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #111111;
    }

    .custom-h5-white {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff;
    }


    .Accent-Underline {
        border-bottom: solid 2px #ec37a0;
        width: 70%;
        margin-bottom: 15px;
    }


    .FullImage-BG {
        width: 100%;
        height: auto;
        min-height: 300px;
        position: static;
        overflow: hidden;
        padding: 25px;
    }


    .Image-Text {
        width: 100%;
        float: none !important;
        height: auto;
        color: #ffffff;
        text-align: left;
    }


    .Image-Link-Section {
        width: 100%;
        float: none !important;
        padding: 15px;
        height: auto;
        color: #ffffff;
        text-align: left;
    }


    .CTA-BTN-Large-Full {
        background-color: #ec37a0;
        border: none;
        color: #ffffff !important;
        padding: 16px 32px;
        text-align: center;
        clear: both !important;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        display: block;
        text-decoration: none !important;
        opacity: 100%;
        width: 100%;
        margin-bottom: 15px !important;
        margin-top: 10px !important;
    }


    .CTA-BTN-Large-Full:hover {
        opacity: 0.85;
        color: #ffffff !important;
        text-decoration: none !important;
        width: 100%;
    }

    .CTA-BTN-Large {
        background-color: #ec37a0;
        border: none;
        color: #ffffff;
        padding: 16px 32px;
        text-align: center;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        margin: 4px 2px;
        opacity: 0.6;
        transition: 0.3s;
        display: block;
        text-decoration: none;
        cursor: pointer;
    }


    .CTA-BTN-Large:hover {
        opacity: 1;
        color: #ffffff;
        text-decoration: none;
    }


    .CTA-BTN-Small {
        background-color: #ec37a0;
        border: none;
        color: #ffffff;
        padding: 8px 12px;
        text-align: center;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 13px;
        margin: 0 auto !important;
        margin-top: 15px !important;
        transition: 0.3s;
        opacity: 1;
        display: block;
        text-decoration: none;
        cursor: pointer;
    }


    .CTA-BTN-Small:hover {
        color: #ffffff;
        text-decoration: none;
        opacity: 0.6;
    }


    .Full-Width-Section-Premium {
        width: 100%;
        min-height: 100px;
        margin-bottom: 30px !important;

    }


    .Full-Width-Section-Basic {
        width: 100%;
        padding: 25px;
        margin-bottom: -25px;
    }



    .Full-Width-Tab {
        width: 100%;
        padding: 0px;
    }


    .Half-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .Half-Width-Comparison-Images {
        width: 50%;
        padding: 2px;
        margin: 0 auto !important;
    }


    .Fourth-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .ThreeFourth-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .Third-Width-Section {
        width: 100%;
        padding: 10px 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .TwoThirds-Width-Section {
        width: 100%;
        padding: 15px 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .Grey-BG {
        background-color: #eaeaea;
        margin: 0 auto !important;
        padding: 20px;
        margin-left: 0px;
        margin-bottom: 30px !important;
    }

    .White-BG {
        background-color: #ffffff;
        margin: 0 auto !important;
        padding: 20px;
        margin-left: 0px;
        margin-bottom: 30px !important;
    }


    .Main-PNG {
        width: 100%;
        max-width: 750px !important;
        margin-top: 25px;
    }


    .Comparison-PNG {
        width: 100%;
        max-width: 400px !important;
    }


    .Outro-Jellybean {
        width: 100%;
        max-width: 500px !important;
        padding: 0px;
        float: none !important;
    }

    .Quick-Stat-Icon-Container {
        min-height: 145px;
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center
    }


    .QuickStats-Container-Half {
        padding: 20px 0px 20px 0px;
        margin-top: 20px;
        margin: 0 auto !important;
    }


    .Quick-Stat-Block-Quarter {
        background-color: #111111;
        width: 100%;
        float: none !important;
        min-height: 100px;
        padding: 20px;
    }

    .Quick-Stat-Block-Third {
        width: 100%;
        padding: 20px;
        float: none !important;
    }

    .Quick-Stat-Block-Half {
        width: 50%;
        float: none !important;
    }


    .Quick-Stat-Title-Light {
        color: #ffffff;
        font-weight: 900;
        text-align: center;
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 18px;
        line-height: 20px;
    }

    .Quick-Stat-Title-Dark {
        color: #111111;
        font-weight: 900;
        text-align: center;
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 18px;
        line-height: 20px;
    }



    .Quick-Stat-Image {
        width: 100%;
        max-width: 250px !important;
        margin-top: 5px;
    }



    .Stats-Color {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 48px;
        line-height: 50px;
        margin: 10px 0px 5px 0px;
        color: #ec37a0;
        vertical-align: bottom;
    }


    .Stats-Dark {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 48px;
        line-height: 50px;
        margin: 10px 0px 5px 0px;
        color: #111111;
        vertical-align: bottom;
    }

    .Stats-Small-Color {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 24px;
        line-height: 26px;
        margin: 10px 0px 5px 0px;
        color: #ec37a0;
        vertical-align: bottom;
    }


    .Stats-Small-Dark {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 24px;
        line-height: 26px;
        margin: 10px 0px 5px 0px;
        color: #111111;
        vertical-align: bottom;
    }

    .Stats-Small-HP {
        font-size: 18px !important;
    }



    /* Style the buttons inside the tab */
    .tab button {
        padding: 7px;
        font-size: 22px;
        min-height: 170px;
        margin: 0 auto !important;
    }



    /* Table/Chart Styling */
    .Disclaimer-Text {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 8px;
        line-height: 10px;
    }


    .Chart-Settings {
        padding: 0px;
        width: 100%;
    }


    .Table-Model-Title-Dark {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 10px;
        color: #111111;
    }

    .Table-Model-Title-Light {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 10px;
        color: #ffffff;
    }


    .Table-ModelImage-BG {
        padding: 10px;
    }


    .Table-ComparisonImage-BG {
        padding: 10px;
    }



    .Table-Category-Block {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        padding: 10px 5px;
    }


    .Table-White-Cell {
        padding: 10px 5px;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 10px;
        line-height: 12px;
    }


    .Table-Grey-Cell {
        padding: 10px 5px;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 10px;
        line-height: 12px;
    }
}


/***** MOBILE CSS *****/
@media screen and (max-width: 619px) and (min-width: 200px) {

    .Full-Width-Flex {
        display: block !important;
    }


    .Half-Width-Section-2COl-Container {
        display: block;
        width: 100% !important;
        margin: auto 0;
        align-items: flex-start !important;
        align-content: space-between !important;
        margin-bottom: 20px !important;
        gap: 20px !important;
    }


    .Half-Width-Section-Image {
        width: 100%;
        margin: 0 auto;
        float: none;
        display: block;
        background-color: #000000 !important;
        color: #ffffff !important;
    }

    .Half-Width-Section-WhiteBG {
        width: 100%;
        margin: auto 0;
        float: none;
        display: block;
        background-color: #ffffff !important;
        color: #ffffff !important;
    }


    .BTN-BACK-TOP {
        display: block !important;
    }

    #animated_div-review {
        width: 100%;
        max-width: 1000px !important;
    }


    .Intro-Full-Width-Container {
        width: 100%;
        min-height: 300px;
        display: block;
    }


    .p-text {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .p-text-bold {
        font-family: 'CustomFont-Bold', sans-serif;
    }


    .AtGlance-Container-Premium {
        margin: 0 auto !important;
        padding: 20px;
        width: 100%;
    }


    .QuickStats-Container-Full {
        margin: 0 auto !important;
        padding: 20px;
        margin-bottom: 20px !important;
    }



    .custom-h1 {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 36px;
        line-height: 35px;
        letter-spacing: 1px;
        font-weight: 700;
        color: #111111;
    }


    .custom-h2 {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 24px;
        line-height: 27px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #111111;
    }


    .custom-h2-white {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 24px;
        line-height: 27px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #FFFFFF;
    }

    .custom-h3 {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #111111;
    }

    .custom-h3-white {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 900;
        color: #FFFFFF;
    }

    .custom-h4 {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #111111;
    }

    .custom-h4-white {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff;
    }


    .custom-h5 {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #111111;
    }

    .custom-h5-white {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff;
    }


    .Dare-to-Compare {
        margin-top: 25px !important;
    }


    .Accent-Underline {
        border-bottom: solid 2px #ec37a0;
        width: 70%;
        margin-bottom: 10px;
    }


    .Video-Title {
        margin-top: 20px;
    }


    .videoWrapper {
        margin: 0 auto !important;
    }


    .FullImage-BG {
        width: 100%;
        height: auto;
        min-height: 300px;
        position: static;
        overflow: hidden;
        padding: 10px;
    }


    .Image-Text {
        width: 100%;
        float: none !important;
        height: auto;
        color: #ffffff;
        text-align: left;
        padding: 15px;
    }

    .Image-Link-Section {
        width: 100%;
        float: none !important;
        padding: 15px;
        height: auto;
        color: #ffffff;
        text-align: left;
    }


    .CTA-BTN-Large-Full {
        background-color: #ec37a0;
        border: none;
        color: #ffffff !important;
        padding: 16px 32px;
        text-align: center;
        clear: both !important;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        display: block;
        text-decoration: none !important;
        opacity: 100%;
        width: 100%;
        margin-bottom: 15px !important;
        margin-top: 10px !important;
    }


    .CTA-BTN-Large-Full:hover {
        opacity: 0.85;
        color: #ffffff !important;
        text-decoration: none !important;
        width: 100%;
    }

    .CTA-BTN-Large {
        background-color: #ec37a0;
        border: none;
        color: #ffffff !important;
        padding: 16px 32px;
        text-align: center;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 16px;
        margin: 4px 2px;
        opacity: 0.6;
        transition: 0.3s;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
    }


    .CTA-BTN-Large:hover {
        opacity: 1;
        color: #ffffff !important;
        text-decoration: none;
    }

    .CTA-BTN-Small {
        background-color: #ec37a0;
        border: none;
        color: #ffffff;
        padding: 5px 5px;
        text-align: center;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 12px;
        margin: 8px 0px;
        transition: 0.3s;
        opacity: 1;
        display: block;
        text-decoration: none;
        cursor: pointer;
        margin-top: 15px !important;
    }


    .CTA-BTN-Small:hover {
        color: #ffffff;
        text-decoration: none;
        opacity: 0.6;
    }


    .Full-Width-Section-Premium {
        width: 100%;
        min-height: 100px;
        margin-bottom: 5px !important;
    }


    .Full-Width-Section-Basic {
        width: 100%;
        padding: 20px 20px 0px 20px;
        min-height: 100px;
        margin-bottom: -25px !important;
    }


    .Full-Width-Tab {
        width: 100%;
        padding: 0px;
        min-height: 300px;
    }

    .Half-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none !important;
        display: block;
    }


    .Half-Width-Comparison-Images {
        width: 50%;
        padding: 1px;
        margin: 0 auto !important;
    }

    .Fourth-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .ThreeFourth-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .Third-Width-Section {
        width: 100%;
        padding: 0px;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .TwoThirds-Width-Section {
        width: 100%;
        margin: 0 auto !important;
        float: none;
        display: block;
    }


    .Grey-BG {
        background-color: #eaeaea;
        margin: 0 auto !important;
        padding: 10px;
        margin-left: 0px;
        margin-bottom: 20px !important;
    }


    .White-BG {
        background-color: #ffffff;
        margin: 0 auto !important;
        padding: 10px;
        margin-left: 0px;
        margin-bottom: 20px !important;
    }


    .Main-PNG {
        width: 100%;
        max-width: 750px !important;
        margin-top: 25px;
    }



    .Comparison-PNG {
        width: 100%;
        max-width: 400px !important;
    }


    .Outro-Jellybean {
        width: 100%;
        max-width: 500px !important;
        padding: 0px;
        float: none !important;
        display: block;
    }

    .Quick-Stat-Block-Quarter {
        background-color: #111111;
        width: 100%;
        float: none !important;
        min-height: 100px;
        padding: 20px;
        margin: 0 auto !important;
    }


    .Quick-Stat-Block-Third {
        width: 100%;
        padding: 20px;
        margin: 0 auto !important;
        float: none !important;
    }


    .Quick-Stat-Block-Half {
        width: 100%;
        padding: 10px 0px;
        margin: 0 auto !important;
        float: none !important;
    }


    .Quick-Stat-Title-Light {
        color: #ffffff;
        font-weight: 900;
        text-align: center;
    }

    .Quick-Stat-Title-Dark {
        color: #111111;
        font-weight: 900;
        text-align: center;
    }


    .Quick-Stat-Image {
        width: 100%;
        max-width: 250px !important;
        margin-top: 15px;
    }


    .Stats-Color {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 38px !important;
        line-height: 40px;
        margin: 10px 0px 5px 0px;
        color: #ec37a0;
        vertical-align: bottom;
    }


    .Stats-Dark {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 38px !important;
        line-height: 40px;
        margin: 10px 0px 5px 0px;
        color: #111111;
        vertical-align: bottom;
    }


    .Stats-Small-Color {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 28px !important;
        line-height: 30px;
        margin: 10px 0px 5px 0px;
        color: #ec37a0;
        vertical-align: bottom;
    }


    .Stats-Small-Dark {
        font-family: 'CustomFont-Bold', sans-serif;
        font-size: 28px !important;
        line-height: 30px;
        margin: 10px 0px 5px 0px;
        color: #111111;
        vertical-align: bottom;
    }

    .Stats-Small-HP {
        font-size: 16px !important;
    }


    .tab button {
        padding: 7px;
        font-size: 12px;
        min-height: 170px;
        margin: 0 auto !important;
    }


    /* Table/Chart Styling */
    .Disclaimer-Text {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 8px;
        line-height: 10px;
    }


    .Chart-Settings {
        padding: 0px;
        width: 100%;
    }


    .Table-Model-Title-Dark {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 10px;
    }

    .Table-Model-Title-Light {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 10px;
    }
    .Table-ModelImage-BG {
        padding: 10px;
    }
    .Table-ComparisonImage-BG {
        padding: 10px;
    }
    .Table-Category-Block {
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        padding: 10px 5px;
    }
    .Table-White-Cell {
        padding: 10px 5px;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 10px;
        line-height: 12px;
    }
    .Table-Grey-Cell {
        padding: 10px 5px;
        font-family: 'CustomFont-Regular', sans-serif;
        font-size: 10px;
        line-height: 12px;
    }
}