.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Select-control{
  height: 40px !important;
  border: unset !important;
}
.Select-placeholder, .Select--single > .Select-control .Select-value{
  border: 2px solid #2ea3ff;
  border-radius: 0.5rem;
}
.bg-blue,.bg-blue:hover{
  background: #2ea3ff;
  color: #fff;
  border: 1px solid #2ea3ff;
}
.ititle h1{ color: #0E175F;
  font-size: 64px;
  font-weight: 500;}
.the-title{padding-left: 0;}
.ititle p{ color: #0E175F;font-size: 15px;}
.ibgblueimg{background-image: url("/images/newui/bg-pattern-lr-dots.png");
  background-color: #0E185F;
  background-position: center center;
  padding: 15px 0;
  background-size: cover;
}
.container-fluid.ibgblueimg img {
  margin-right: 14px;
  max-width: 76px;
}
.container-fluid.ibgblueimg p {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding-top: 2px;
  margin-bottom: 0;
}
.textblue{color: #0E175F !important;}
.location-icon{color: #2FA4FF !important; margin-right: 3px;}
.dark-gray-para{color: #4e4d4d !important;}
.btn-white{background-color: white;}
.searchtop{background: linear-gradient(360deg, #D0DFF3, transparent);padding: 18px 50px;}
.ilargetext{font-size: 22px;line-height: 25px;}
.locationtext{font-size: 10px;}
.car_price span{font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #0E175F;}
.car_price h5 {
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
     letter-spacing:unset;
    color: #0E175F;
    margin: 4px 0 4px;
    position: relative;
}
.car_price h5::first-letter{font-size: 55%;line-height: 26px;margin-top: -10px;position: absolute;top:-10px;}
.priceborderright{border-right: 2px solid #2EA3FF;}
.btn-findoutmore{
  border-radius: 25px;
  border: 2px solid #2DA2FF;
  background-color: #fff;
  color: #0E175F;
  font-size: 12px;
  padding: 0 27px;
  font-weight: normal;
  line-height: 36px;
}
.btn-downloadapp{
  border-radius: 25px;
  border: 2px solid #2DA2FF;
  background-color: #2DA2FF;
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
  font-weight: normal;
  line-height: 36px;
}
.btn-downloadapp:hover,.btn-findoutmore:hover{
  border: 2px solid #0E175F;
  background-color: #ffffff;
  color: #0E175F;
}
.sort_box .form-control {
  height: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #2DA2FF;
  margin-right: 0;
  background: none !important;
  border: 2px solid #2DA2FF;
  padding: 5px 19px;
  box-shadow: none;
  width: 80px;
}
.dialog-downloadpp-container{width: 500px;min-height: 250px;background: #fff;position: relative;border-radius: 8px;}
.product_dtl .table td { border-top: unset;border-bottom: unset;padding: 3px 10px;}
.bg-dark-blue{background-color: #0E175F; color: #fff;}
#driveItWantItSlider span.title{
  z-index: 10;
  font-style: italic;
  height: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: normal;
  width: 193px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 32px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 30px;}
  #driveItWantItSlider p.text-white{
    height: auto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    width: 300px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: left;
    line-height: 26px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 18px;margin-top:15px;}
  #driveItWantItCarousel .tp-tab-title {
      color: var(--bs-primary);
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      padding: 0 5px;
      position: relative;
      text-align: center;
      transition: all 0.3s;
      cursor: pointer;
  }
#driveItWantItCarousel .tp-tab-border {
    background-color: rgba(0,0,0,0.25);
    border-radius: 20px;
    content: '';
    height: 4px;
    margin-top: 10px;
    width: 100%;
}
#driveItWantItCarousel .flex-active-slide .tp-tab-border {
  background-color: rgba(0,0,0,0.5);
}
.bottom-border{border-bottom: 1px solid rgba(0,0,0,0.25);}
.tab_box .nav-tabs .nav-link.active{color: #2ea3ff;
  border-bottom: 5px solid #2ea3ff;}
.fa-map-marker.textblue{font-size: 1.9em;}
.btn.pricing_btn{border:2px solid #2ea3ff;}
.fontsizelarge{font-size: 58px; line-height:62px;}
.fontsizesmall{font-size: 48px; line-height:52px;}
.product_sec .carousel{background: unset;}
#slider .flex-viewport .slides li img {
  max-width: 446px !important;
  min-width: 385px !important;
  max-height: 300px !important;
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
  align-self: center;
}
.carlistattrblock span.pull-right{max-width: 78px;}
#main-menu>.menu-item>a.nav-link {
  color: #2ea3ff;
  font-size: 16px;
  font-weight: bold;
  margin: -0.5rem 0 -0.5rem;
  padding: 1.5rem 0;
}
#main-menu>.menu-item.menu-cta>a.nav-link {
  border: 2px solid #2ea3ff;
  border-radius: 0.5rem;
  color: #0e175f;
  font-size: 18px;
  margin: 10px 0 0;
  padding: 12px 60px;
  text-align: center;
}
.offcanvas.offcanvas-end.show #main-menu li{width: 100%;border-bottom: 1px solid #d7d7d7;position: relative;}
.flexslider{background: unset;border: unset;}
.product_dtl .head_h5{word-break: initial;}
@media (max-width: 1199px) and (min-width:992px){
  .fontsizelarge {font-size: 45px;line-height: 49px;}
  .fontsizesmall {font-size: 38px;line-height: 44px;}
  .product_dtl .head_h5 {font-size: 13px;}
  .btn.pricing_btn small{font-size: 15px;font-weight: 700;}
  .wp-block-image-h4{font-size: 16px;}
  #driveItWantItSlider p.text-white{width: 200px;font-size: 16px;line-height: 20px;}
  .container-fluid.ibgblueimg p{font-size: 13px;}
  .ititle h1 {font-size: 55px;}
  .car_price h5{font-size: 15px;line-height: 26px}
  .locationtext {font-size: 8px;}
  .car_box .btn-downloadapp{font-size: 10px;}
  .car_box .btn-findoutmore{font-size: 10px; padding: 0 17px;}
 .select_sec .btn.pricing_btn{padding: 11px 10px;}
   #slider .flex-viewport .slides li img {
    max-width: 350px !important;
    min-width: 350px !important;
    max-height: 270px !important;
  } 

}
@media (max-width: 991px) and (min-width: 768px){
  .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid, .navbar-expand-lg>.container-lg, .navbar-expand-lg>.container-md, .navbar-expand-lg>.container-sm, .navbar-expand-lg>.container-xl {
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .flex-direction-nav a.flex-next:before,.flex-direction-nav a.flex-prev:before{font-size: 23px;}
 
  .fontsizelarge {font-size: 38px;line-height: 42px;}
  .fontsizesmall {font-size: 30px;line-height: 34px;}
  .product_dtl .head_h5{font-size: 11px;}
  .btn.pricing_btn small{    font-size: 12px;font-weight: 700;}
  .wp-block-image-h4{font-size: 14px;}
  #driveItWantItSlider span.title{font-size: 24px;}
  #driveItWantItSlider p.text-white{width: 180px;font-size: 15px;line-height: 20px;}
  .fs-md-5 {font-size: 1.0rem !important;}
  .ititle h1{font-size: 44px;}
  .select_sec .btn.pricing_btn{padding: 11px 10px;}
  .detailpagebtnblock .download{padding: 7px 5px !important;}
  #slider .flex-viewport .slides li img {
    max-width: 300px !important;
    min-width: 300px !important;
    max-height: 270px !important;
  } 
}

@media (max-width: 767px) {

  #driveItWantItSlider span.title{
    width: 193px;
    line-height: 27px;
    font-size: 25px;
  }
  #driveItWantItSlider p.text-white{
      width: 157px;
      line-height: 17px;
      font-size: 13px;
    }
  .searchtop{
    padding: 0;
  }
  .ititle h1{font-size: 50px;}
  .flex-direction-nav a.flex-next:before,.flex-direction-nav a.flex-prev:before{font-size: 23px;}
  .buy_car.product_dtl {width: 50%;float: left;    margin-bottom: 12px;}
  .fontsizelarge {font-size: 41px;line-height: 48px;}
  .fontsizesmall {font-size: 36px;line-height: 42px;}
  .site-header{padding: 0 10px;}
  #slider .flex-viewport .slides li img {
    max-width: 300px !important;
    min-width: 300px !important;
    max-height: 270px !important;
  } 
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
